import React from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"
import logoBlack from '../images/logo-black.jpg';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="about-background sma:h-64"></div>
    <section className="lg:flex container mx-auto mt-16 my-20">
      <div className="w-full md:w-4/5 py-6 md:m-10">
        <img src={logoBlack} className="h-32 w-48 m-auto"/>
      </div>
      <div className="w-full md:w-4/5 lg:m-auto sm:mt-10">
        <h1 className="text-4xl text-hover">Privacy Policy</h1>
        <div className="w-full bg-gray-900 h-px"></div>
        <p className="leading-relaxed mt-2 text-gray-700 font-thin">We at Cymetrix value your privacy and are committed to protecting your personal information. This privacy policy explains what data we collect, use, and process, as well as your rights and how to contact us with any questions. </p>
        <p className="leading-relaxed mt-2 text-gray-700 font-thin">We reserve the right to update this privacy policy at any time, so please check back from time to time to stay informed. </p>

        <span className="mt-4 block text-gray-800 text-lg">Who does this apply to? </span>
        <p className="leading-relaxed mt-2 text-gray-700 font-thin">Our privacy policy statement applies to any individual who interacts with our website or subscribes to any of our services or products.  </p>

        <span className="mt-4 block text-gray-800 text-lg">How do we collect your Data?  </span>

        <div className="leading-loose mt-2  mx-5 text-gray-700 font-thin">
          <ul className="list-disc">
              <li>When you register on our website: This will include your name and email address. We may ask you to provide additional information about you or your business when you fill out the online form on our website.</li>
              <li>When you apply for a job:This will include your name, email address, and CV/Resume.</li>
              <li>Cookies: Cookies are tiny data snippets stored on your device when you visit our website. They help us track web traffic and your online activity. We collect this data with your implied consent, meaning we assume you agree to our terms of service unless you opt-out. To do so, change your browser settings to block cookies or browse in private mode.</li>
              <li>Google Analytics:Google Analytics collects data related to devices/browsers, IP addresses, and your activities on the website to measure and report analytics about your interactions on the web. We use it to enhance the user experience and track data related to our online marketing activities. To know more, we suggest checking out Google Analytic’s privacy policy.</li>
              <li>External and Third-party links: We have linked our website with third-party websites/pages to provide better information and clarity to our visitors. Whenever you visit any of the links to the third-party website, they use or collect data as per their privacy policies. We suggest you check out their privacy policy. </li>
              <li>Social Plugins: We have interlinked our social media platforms on our website for more interaction and to enhance the visibility of our content. Whenever you visit any of the links to the social media platforms, they collect or use your data as per their own privacy policies. We recommend checking them out: Facebook, Linkedin, and Twitter.</li>

            </ul>
        </div>


        <span className="mt-4 block text-gray-800 text-lg">Why do we collect Data?   </span>

        <div className="leading-loose mt-2  mx-5 text-gray-700 font-thin">
          <ul className="list-disc">
              <li>To offer personalized services</li>
              <li>For Marketing purposes</li>
              <li>Create a secure platform</li>
              <li>Enhance performance and user experience</li>
              <li>For internal research purposes</li>
            </ul>
        </div>


        <span className="mt-4 block text-gray-800 text-lg">How do we keep your Data secure?  </span>
        <p className="leading-relaxed mt-2 text-gray-700 font-thin">We take data protection seriously. We use appropriate technical and organizational measures to protect your data from unauthorized or unlawful processing, accidental loss, alteration, and disclosure. If we detect a data breach, we will promptly notify the relevant authorities and affected users.  </p>

        <span className="mt-4 block text-gray-800 text-lg">What rights do you have?  </span>
        <p className="leading-relaxed mt-2 text-gray-700 font-thin">You have the right to access your data, request corrections or removal, and object to its processing.  </p>

        <p className="leading-relaxed mt-2 text-gray-700 font-thin">You can contact info@cymetrixsoft.com to request your personal information, or in case you have any questions regarding your privacy policy.    </p>

       </div>
    </section>
  </Layout>
)

export default AboutPage;